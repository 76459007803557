import axios from 'axios'
import {UserWorkPreferencesModel, UserProfessionalProfileModel} from '../interfaces/_models'

const API_URL_GET_JOB_PREFERENCES_BY_TOKEN = `${process.env.REACT_APP_API_URL_GET_JOB_PREFERENCES_BY_TOKEN}`
const API_URL_UPDATE_JOB_PREFERENCES_BY_TOKEN = `${process.env.REACT_APP_API_URL_UPDATE_JOB_PREFERENCES_BY_TOKEN}`
const API_URL_GET_PROFESSIONAL_PROFILE_BY_TOKEN = `${process.env.REACT_APP_API_URL_GET_PROFESSIONAL_PROFILE_BY_TOKEN}`
const API_URL_UPDATE_PROFESSIONAL_PROFILE_BY_TOKEN = `${process.env.REACT_APP_API_URL_UPDATE_PROFESSIONAL_PROFILE_BY_TOKEN}`

export function getUserWorkPreferencesByToken(token: string) {
  console.log('api getUserWorkPreferencesByToken')
  return axios.post<UserWorkPreferencesModel>(API_URL_GET_JOB_PREFERENCES_BY_TOKEN, {
    api_token: token,
  })
}

export async function updateUserWorkPreferencesByToken(token: string, job_preferences: UserWorkPreferencesModel) {
  console.log('api updateUserWorkPreferencesByToken')
  return axios.post(API_URL_UPDATE_JOB_PREFERENCES_BY_TOKEN, {api_token: token, job_preferences: job_preferences})
}

export function getUserProfessionalProfileByToken(token: string) {
  console.log('api getUserProfessionalProfileByToken')
  return axios.post<UserProfessionalProfileModel>(API_URL_GET_PROFESSIONAL_PROFILE_BY_TOKEN, {
    api_token: token,
  })
}

export async function updateUserProfessionalProfileByToken(token: string, professional_profile: UserProfessionalProfileModel) {
  console.log('api updateUserProfessionalProfileByToken')
  return axios.post(API_URL_UPDATE_PROFESSIONAL_PROFILE_BY_TOKEN, {api_token: token, professional_profile: professional_profile})
}
