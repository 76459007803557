import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {JobMatchesDetails} from './components/cards/JobMatchesDetails'

const JobMatchesBreadCrumbs: Array<PageLink> = [
  {
    title: 'Job matches',
    path: 'user/job-matches',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const JobMatchesPage: React.FC = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <Outlet />
          </>
        }
      >
        <Route
          path='list'
          element={
            <>
              <PageTitle breadcrumbs={JobMatchesBreadCrumbs}>List</PageTitle>
              <JobMatchesDetails />
            </>
          }
        />
        <Route index element={<Navigate to='/user/job-matches/list' />} />
      </Route>
    </Routes>
  )
}

export default JobMatchesPage
