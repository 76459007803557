import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {getUserProfessionalProfileByToken, updateUserProfessionalProfileByToken} from '../../../../../../api/_requests'
import {getAuth} from '../../../../../../modules/auth'
import {UserProfessionalProfileModel} from '../../../../../../interfaces/_models'
import {_education_levels, _industries, _positions, _programming_languages, _technologies} from '../../../../../../constants/_data'
import {initialValues} from '../../../constants/_data'
import {useIntl} from 'react-intl'

const ProfessionalProfileDetails: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [userProfessionalProfileInitialValues, setUserProfessionalProfileInitialValues] = useState<UserProfessionalProfileModel>(initialValues) // Por defecto se carga initialValues. así que si da error 400 que no exista.
  const auth = getAuth()
  const [submitResponseAlertMessage, setSubmitResponseAlertMessage] = useState({
    title: '',
    text: '',
    type: '', // 'success' o 'error'
  })
  const intl = useIntl()
  useEffect(() => {
    setLoading(true)
    setTimeout(async () => {
      try {
        const response = await getUserProfessionalProfileByToken(auth?.api_token ?? '')

        if (response && response.status === 200 && response.data) {
          const updatedUserProfessionalProfileInitialValues = Object.assign(initialValues, response.data)
          setUserProfessionalProfileInitialValues(updatedUserProfessionalProfileInitialValues)
        }
      } catch (error) {
        console.error('Error:', error)
      } finally {
        setLoading(false)
      }
    }, 0)
  }, [])

  const formik = useFormik<UserProfessionalProfileModel>({
    initialValues: userProfessionalProfileInitialValues,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(async () => {
        try {
          const updateUserProfessionalProfile = Object.assign(initialValues, values)
          const response = await updateUserProfessionalProfileByToken(auth?.api_token ?? '', updateUserProfessionalProfile)
          if (response && response.status === 200) {
            setSubmitResponseAlertMessage({
              title: intl.formatMessage({
                id: 'ProfessionalProfileDetails.Alert.SuccessTitle',
              }),
              text: intl.formatMessage({
                id: 'ProfessionalProfileDetails.Alert.SuccessText',
              }),
              type: 'success',
            })
          } else {
            setSubmitResponseAlertMessage({
              title: intl.formatMessage({
                id: 'ProfessionalProfileDetails.Alert.ErrorTitle',
              }),
              text:
                intl.formatMessage({
                  id: 'ProfessionalProfileDetails.Alert.ErrorText',
                }) + response.statusText,
              type: 'error',
            })
          }
        } catch (error) {
          console.error('Error:', error)
          // Muestra el mensaje de error en caso de una excepción
          setSubmitResponseAlertMessage({
            title: intl.formatMessage({
              id: 'ProfessionalProfileDetails.Alert.ExceptionTitle',
            }),
            text:
              intl.formatMessage({
                id: 'ProfessionalProfileDetails.Alert.ExceptionText',
              }) + error,
            type: 'error',
          })
        }
        setLoading(false)
      }, 0)
    },
  })

  return (
    <div>
      {submitResponseAlertMessage.type && (
        <div
          className={`card alert alert-dismissible ${
            submitResponseAlertMessage.type === 'success' ? 'bg-light-success' : 'bg-light-danger'
          } d-flex flex-column flex-sm-row p-5 mb-10`}
        >
          <div className='d-flex flex-column pe-0 pe-sm-10'>
            <h5 className={`mb-1 ${submitResponseAlertMessage.type === 'error' ? 'text-danger' : ''}`}>{submitResponseAlertMessage.title}</h5>
            <span>{submitResponseAlertMessage.text}</span>
          </div>
          <button type='button' className='position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon ms-sm-auto' data-bs-dismiss='alert'>
            <span className={`svg-icon svg-icon-1 ${submitResponseAlertMessage.type === 'error' ? 'svg-icon-danger' : 'svg-icon-success'} fw-bolder`}>X</span>
          </button>
        </div>
      )}

      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>
              {intl.formatMessage({
                id: 'ProfessionalProfileDetails.Form.Title',
              })}
            </h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='card-body border-top p-9'>
              <p className='fw-semibold fs-6 mb-6'>
                {intl.formatMessage({
                  id: 'ProfessionalProfileDetails.Form.Description',
                })}
              </p>

              <div className={`row mb-6 p-2 align-items-center`}>
                <div className='form-check form-check-custom form-check-solid col-lg-4 col-form-label'>
                  <label className='col-form-label fw-bold fs-6' htmlFor='flexActiveCheckMinimumSalary'>
                    {intl.formatMessage({
                      id: 'ProfessionalProfileDetails.Form.YearsOfExperience',
                    })}
                  </label>
                </div>
                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-2 fv-row'>
                      <input
                        type='number'
                        min='0'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        {...formik.getFieldProps('experience.values.years')}
                      />
                    </div>
                  </div>
                  <div className='form-text'>
                    {intl.formatMessage({
                      id: 'ProfessionalProfileDetails.Form.MinimumSalary',
                    })}
                  </div>
                </div>
              </div>

              <div className={`row mb-6 p-2 align-items-center`}>
                <div className='form-check form-check-custom form-check-solid col-lg-4 col-form-label'>
                  <label className='col-form-label fw-bold fs-6' htmlFor='flexActiveCheckWorkModalities'>
                    {intl.formatMessage({
                      id: 'ProfessionalProfileDetails.Form.EducationLevel',
                    })}
                  </label>
                </div>

                <div className='col-lg-8'>
                  <div className='row'>
                    <div className='col-lg-4 fv-row'>
                      <select className='form-select form-select-solid form-select-lg' {...formik.getFieldProps('education.values.level')}>
                        {_education_levels.map((education_level) => (
                          <option key={education_level.value} value={education_level.value}>
                            {education_level.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='form-text'>
                    {intl.formatMessage({
                      id: 'ProfessionalProfileDetails.Form.HighestEducationLevel',
                    })}
                  </div>
                </div>
              </div>

              <div className={`row mb-6 p-3 align-items-center`}>
                <div className='form-check form-check-custom form-check-solid col-lg-4 col-form-label'>
                  <label className='col-form-label fw-bold fs-6' htmlFor='flexActiveCheckProgrammingLanguages'>
                    {intl.formatMessage({
                      id: 'ProfessionalProfileDetails.Form.ProgrammingLanguages',
                    })}
                  </label>
                </div>

                <div className='col-lg-8'>
                  {_programming_languages.map((option, index) => (
                    <div key={index} className='d-inline-block me-4'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm me-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          {...formik.getFieldProps(`programming_languages.values.${option.key}`)}
                          checked={formik.values.programming_languages?.values[option.key] || false}
                          id={`flexActiveCheckProgramingLanguages${option.key.charAt(0).toUpperCase() + option.key.slice(1)}`}
                        />
                        <label
                          className='form-check-label text-gray-700 fw-bold fs-6'
                          htmlFor={`flexActiveCheckProgramingLanguages${option.key.charAt(0).toUpperCase() + option.key.slice(1)}`}
                        >
                          {option.label}
                        </label>
                      </div>
                    </div>
                  ))}
                  <div className='form-text'>
                    {intl.formatMessage({
                      id: 'ProfessionalProfileDetails.Form.PreferredProgrammingLanguages',
                    })}
                  </div>
                </div>
              </div>

              <div className={`row mb-6 p-3 align-items-center`}>
                <div className='form-check form-check-custom form-check-solid col-lg-4 col-form-label'>
                  <label className='col-form-label fw-bold fs-6' htmlFor='flexActivePositions'>
                    {intl.formatMessage({
                      id: 'ProfessionalProfileDetails.Form.Positions',
                    })}
                  </label>
                </div>

                <div className='col-lg-8'>
                  {_positions.map((option, index) => (
                    <div key={index} className='d-inline-block me-4'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm me-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          {...formik.getFieldProps(`positions.values.${option.key}`)}
                          checked={formik.values.positions?.values[option.key] || false}
                          id={`flexActiveCheckPositions${option.key.charAt(0).toUpperCase() + option.key.slice(1)}`}
                        />
                        <label
                          className='form-check-label text-gray-700 fw-bold fs-6'
                          htmlFor={`flexActiveCheckPositions${option.key.charAt(0).toUpperCase() + option.key.slice(1)}`}
                        >
                          {option.label}
                        </label>
                      </div>
                    </div>
                  ))}
                  <div className='form-text'>
                    {intl.formatMessage({
                      id: 'ProfessionalProfileDetails.Form.DesiredPositions',
                    })}
                  </div>
                </div>
              </div>

              <div className={`row mb-6 p-3 align-items-center`}>
                <div className='form-check form-check-custom form-check-solid col-lg-4 col-form-label'>
                  <label className='col-form-label fw-bold fs-6' htmlFor='flexActiveTechnologies'>
                    {intl.formatMessage({
                      id: 'ProfessionalProfileDetails.Form.Technologies',
                    })}
                  </label>
                </div>

                <div className='col-lg-8'>
                  {_technologies.map((option, index) => (
                    <div key={index} className='d-inline-block me-4'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm me-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          {...formik.getFieldProps(`technologies.values.${option.key}`)}
                          checked={formik.values.technologies?.values[option.key] || false}
                          id={`flexActiveCheckTechnologies${option.key.charAt(0).toUpperCase() + option.key.slice(1)}`}
                        />
                        <label
                          className='form-check-label text-gray-700 fw-bold fs-6'
                          htmlFor={`flexActiveCheckTechnologies${option.key.charAt(0).toUpperCase() + option.key.slice(1)}`}
                        >
                          {option.label}
                        </label>
                      </div>
                    </div>
                  ))}
                  <div className='form-text'>
                    {intl.formatMessage({
                      id: 'ProfessionalProfileDetails.Form.RelevantTechnologies',
                    })}
                  </div>
                </div>
              </div>

              <div className={`row mb-6 p-3 align-items-center`}>
                <div className='form-check form-check-custom form-check-solid col-lg-4 col-form-label'>
                  <label className='col-form-label fw-bold fs-6' htmlFor='flexActiveTechnologies'>
                    {intl.formatMessage({
                      id: 'ProfessionalProfileDetails.Form.Industries',
                    })}
                  </label>
                </div>

                <div className='col-lg-8'>
                  {_industries.map((option, index) => (
                    <div key={index} className='d-inline-block me-4'>
                      <div className='form-check form-check-custom form-check-solid form-check-sm me-2'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          {...formik.getFieldProps(`industries.values.${option.key}`)}
                          checked={formik.values.industries?.values[option.key] || false}
                          id={`flexActiveCheckIndustries${option.key.charAt(0).toUpperCase() + option.key.slice(1)}`}
                        />
                        <label
                          className='form-check-label text-gray-700 fw-bold fs-6'
                          htmlFor={`flexActiveCheckIndustries${option.key.charAt(0).toUpperCase() + option.key.slice(1)}`}
                        >
                          {option.label}
                        </label>
                      </div>
                    </div>
                  ))}
                  <div className='form-text'>
                    {intl.formatMessage({
                      id: 'ProfessionalProfileDetails.Form.RelevantIndustries',
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading &&
                  intl.formatMessage({
                    id: 'ProfessionalProfileDetails.Form.SaveButton',
                  })}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({
                      id: 'ProfessionalProfileDetails.Form.SaveButtonLoading',
                    })}{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export {ProfessionalProfileDetails}
