/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {Link} from 'react-router-dom'

const FAQPage: FC = () => {
  return (
    <div className='docs-wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
      <div id='kt_docs_header' className='docs-header align-items-stretch mb-2 mb-lg-10'>
        <div className='container'>
          <div className='d-flex align-items-stretch justify-content-between py-3 h-75px'>
            <div className='d-flex d-lg-none align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'></div>
            <div className='d-flex align-items-center justify-content-between flex-lg-grow-1'>
              <div className='d-flex align-items-center' id='kt_docs_header_title'>
                <div className='d-flex align-items-center'></div>
                <Link to='/' className='my-1'>
                  <img alt='Logo' src={toAbsoluteUrl('/media/logos/favicon.png')} className='h-30px align-self-center' />
                </Link>
                <div className='docs-page-title d-flex flex-column flex-lg-row align-items-lg-center py-5 mb-lg-0 mx-4'>
                  <Link to='/faq'>
                    <h1 className='d-flex align-items-center text-gray-900 my-1 fs-4 fw-bold'>FAQ</h1>
                  </Link>
                  <span className='d-none d-lg-block bullet h-20px w-1px bg-secondary mx-4'></span>
                  <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-6 my-1'>
                    <li className='breadcrumb-item text-gray-60'>
                      <span className='bullet bg-gray-200 w-5px h-2px'></span>
                    </li>
                    <li className='breadcrumb-item mr-2'>
                      <Link to='/contact-us'>Contact Us</Link>
                    </li>
                    <li className='breadcrumb-item mx-2'>
                      <Link to='/faq' className='fw-bolder'>
                        FAQ
                      </Link>
                    </li>
                    <li className='breadcrumb-item mx-2'>
                      <Link to='/privacy-policy'>Privacy Policy</Link>
                    </li>
                    <li className='breadcrumb-item ml-2'>
                      <Link to='/terms-and-conditions'>Terms and Conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className='d-flex align-items-center'>
                <a href='/' className='btn btn-primary btn-flex h-40px border-0 fw-bold px-4 px-lg-6'>
                  <i className='bi bi-arrow-return-left fs-4 me-2 fw-bold'></i> Back to main page
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='kt_docs_content' className='docs-content d-flex flex-column flex-column-fluid'>
        <div className='container' id='kt_docs_content_container'>
          <div className='card mb-2'>
            <div className='card-body fs-6 py-15 px-10 py-lg-15 px-lg-15'>
              <h1 className='anchor fw-bolder mb-5' id='overview'>
                FAQ
              </h1>
              <div className='accordion accordion-icon-toggle' id='kt_accordion_2'>
                <div className='mb-5'>
                  <div className='accordion-header py-3 d-flex' data-bs-toggle='collapse' data-bs-target='#kt_accordion_2_item_1'>
                    <span className='accordion-icon'>
                      <KTSVG className='svg-icon svg-icon-4' path='media/icons/duotune/arrows/arr064.svg' />
                    </span>
                    <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>How do I register as a candidate on mydreamITjob?</h3>
                  </div>
                  <div id='kt_accordion_2_item_1' className='fs-6 collapse show ps-10' data-bs-parent='#kt_accordion_2'>
                    To register, simply go to our registration page and fill out the form with your personal details and qualifications. Make sure to provide accurate
                    information to enhance your chances of finding the right job opportunities.
                  </div>
                </div>
                <div className='mb-5'>
                  <div className='accordion-header py-3 d-flex collapsed' data-bs-toggle='collapse' data-bs-target='#kt_accordion_2_item_2'>
                    <span className='accordion-icon'>
                      <KTSVG className='svg-icon svg-icon-4' path='media/icons/duotune/arrows/arr064.svg' />
                    </span>
                    <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>What type of job opportunities can I find on mydreamITjob?</h3>
                  </div>
                  <div id='kt_accordion_2_item_2' className='collapse fs-6 ps-10' data-bs-parent='#kt_accordion_2'>
                    We connect candidates with a variety of roles across multiple industries, including IT, marketing, finance, engineering, and more. You can filter job
                    opportunities based on your skills, preferred location, and career interests.
                  </div>
                </div>
                <div className='mb-5'>
                  <div className='accordion-header py-3 d-flex collapsed' data-bs-toggle='collapse' data-bs-target='#kt_accordion_2_item_3'>
                    <span className='accordion-icon'>
                      <KTSVG className='svg-icon svg-icon-4' path='media/icons/duotune/arrows/arr064.svg' />
                    </span>
                    <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>Is there a fee for candidates to use mydreamITjob?</h3>
                  </div>
                  <div id='kt_accordion_2_item_3' className='collapse fs-6 ps-10' data-bs-parent='#kt_accordion_2'>
                    No, our platform is completely free for candidates. We believe in facilitating your job search without any barriers.
                  </div>
                </div>
                <div className='mb-5'>
                  <div className='accordion-header py-3 d-flex collapsed' data-bs-toggle='collapse' data-bs-target='#kt_accordion_2_item_4'>
                    <span className='accordion-icon'>
                      <KTSVG className='svg-icon svg-icon-4' path='media/icons/duotune/arrows/arr064.svg' />
                    </span>
                    <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>How does mydreamITjob protect my personal data?</h3>
                  </div>
                  <div id='kt_accordion_2_item_4' className='collapse fs-6 ps-10' data-bs-parent='#kt_accordion_2'>
                    We take your privacy seriously. All personal data is protected with strong security measures and is processed in accordance with our Privacy Policy,
                    ensuring compliance with relevant data protection regulations.
                  </div>
                </div>

                <div className='mb-5'>
                  <div className='accordion-header py-3 d-flex collapsed' data-bs-toggle='collapse' data-bs-target='#kt_accordion_2_item_5'>
                    <span className='accordion-icon'>
                      <KTSVG className='svg-icon svg-icon-4' path='media/icons/duotune/arrows/arr064.svg' />
                    </span>
                    <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>Can I update my profile information?</h3>
                  </div>
                  <div id='kt_accordion_2_item_5' className='collapse fs-6 ps-10' data-bs-parent='#kt_accordion_2'>
                    Yes, you can update your profile information at any time by logging into your account and accessing the profile settings. Keeping your profile updated
                    is crucial for attracting potential employers.
                  </div>
                </div>

                <div className='mb-5'>
                  <div className='accordion-header py-3 d-flex collapsed' data-bs-toggle='collapse' data-bs-target='#kt_accordion_2_item_6'>
                    <span className='accordion-icon'>
                      <KTSVG className='svg-icon svg-icon-4' path='media/icons/duotune/arrows/arr064.svg' />
                    </span>
                    <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>How do I apply for a job on mydreamITjob?</h3>
                  </div>
                  <div id='kt_accordion_2_item_6' className='collapse fs-6 ps-10' data-bs-parent='#kt_accordion_2'>
                    Once you find a job listing that interests you, click the "Apply" button on the job detail page and follow the instructions. You may need to provide
                    additional documents or information, depending on the employer's requirements.
                  </div>
                </div>

                <div className='mb-5'>
                  <div className='accordion-header py-3 d-flex collapsed' data-bs-toggle='collapse' data-bs-target='#kt_accordion_2_item_7'>
                    <span className='accordion-icon'>
                      <KTSVG className='svg-icon svg-icon-4' path='media/icons/duotune/arrows/arr064.svg' />
                    </span>
                    <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>What should I do if I haven’t heard back from an employer?</h3>
                  </div>
                  <div id='kt_accordion_2_item_7' className='collapse fs-6 ps-10' data-bs-parent='#kt_accordion_2'>
                    While employers typically respond within a few weeks, response times can vary. You can check the status of your applications in your account under the
                    'Applications' section. If needed, you can also follow up directly with the employer via their provided contact information.
                  </div>
                </div>

                <div className='mb-5'>
                  <div className='accordion-header py-3 d-flex collapsed' data-bs-toggle='collapse' data-bs-target='#kt_accordion_2_item_8'>
                    <span className='accordion-icon'>
                      <KTSVG className='svg-icon svg-icon-4' path='media/icons/duotune/arrows/arr064.svg' />
                    </span>
                    <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>How often are new job postings updated on the platform?</h3>
                  </div>
                  <div id='kt_accordion_2_item_8' className='collapse fs-6 ps-10' data-bs-parent='#kt_accordion_2'>
                    New job postings are updated daily. We encourage you to visit the site regularly and set up job alerts to stay informed about the latest opportunities
                    that match your profile.
                  </div>
                </div>

                <div className='mb-5'>
                  <div className='accordion-header py-3 d-flex collapsed' data-bs-toggle='collapse' data-bs-target='#kt_accordion_2_item_9'>
                    <span className='accordion-icon'>
                      <KTSVG className='svg-icon svg-icon-4' path='media/icons/duotune/arrows/arr064.svg' />
                    </span>
                    <h3 className='fs-4 text-gray-800 fw-bold mb-0 ms-4'>Can I delete my account on mydreamITjob?</h3>
                  </div>
                  <div id='kt_accordion_2_item_9' className='collapse fs-6 ps-10' data-bs-parent='#kt_accordion_2'>
                    Yes, if you decide to no longer use our service, you can request account deletion by contacting our support team at support@mydreamitjob.com. Please
                    note that deleting your account will remove all your data from our platform.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export {FAQPage}
