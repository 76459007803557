import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      {/* <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' /> */}
      <MenuItem title={intl.formatMessage({id: 'MenuInner.JobMatches.Title'})} to='/user/job-matches' />
      <MenuItem title={intl.formatMessage({id: 'MenuInner.ProfessionalProfile.Title'})} to='/user/professional-profile' />
      <MenuItem title={intl.formatMessage({id: 'MenuInner.WorkPreferences.Title'})} to='/user/work-preferences' />
    </>
  )
}
