/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem to='/user/job-matches' icon='element-11' title={intl.formatMessage({id: 'SidebarMenuItem.JobMatches.Title'})} fontIcon='bi-app-indicator' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          {/* <span className='menu-section text-muted fw-bolder fs-7'>User Input Required</span> */}
          {/* <span className='menu-section text-muted fw-bolder fs-7'>Profile Setup</span> */}
          <span className='menu-section text-muted fw-bolder fs-7'>User Information</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/user/professional-profile'
        icon='code'
        title={intl.formatMessage({id: 'SidebarMenuItem.ProfessionalProfile.Title'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem to='/user/work-preferences' icon='switch' title={intl.formatMessage({id: 'SidebarMenuItem.WorkPreferences.Title'})} fontIcon='bi-app-indicator' />
    </>
  )
}

export {SidebarMenuMain}
