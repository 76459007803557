/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login, loginWithLinkedin} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {useLinkedIn} from 'react-linkedin-login-oauth2'
import {useIntl} from 'react-intl'

const LINKEDIN_CLIENT_ID = `${process.env.REACT_APP_LINKEDIN_CLIENT_ID}`

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Wrong email format').min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Email is required'),
  password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
})

const initialValues = {
  email: 'admin@demo.com',
  password: 'demo',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const intl = useIntl()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus(intl.formatMessage({id: 'Login.LoginFailed'}))
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  // LinkedIn
  const [code, setCode] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  // https://learn.microsoft.com/en-us/linkedin/shared/integrations/people/profile-api?context=linkedin%2Fcompliance%2Fcontext&view=li-lms-unversioned&preserve-view=true
  const {linkedInLogin} = useLinkedIn({
    clientId: LINKEDIN_CLIENT_ID || '',
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      setLoading(true)
      setCode(code)
      // console.log('code: ', code)
    },
    scope: 'openid,profile,email', //r_liteprofile,r_emailaddress,r_compliance, r_primarycontact
    // scope: 'r_emailaddress', //r_liteprofile,r_emailaddress,r_compliance, r_primarycontact
    onError: (error) => {
      // console.log(error)
      setErrorMessage(error.errorMessage)
    },
  })

  useEffect(() => {
    const authAndSetUser = async () => {
      if (code !== '') {
        try {
          const {data: auth} = await loginWithLinkedin(code, `${window.location.origin}/linkedin`) // 1- hago login con post para obtener el toekn auth

          saveAuth(auth)

          const {data: user} = await getUserByToken(auth.api_token)
          setCurrentUser(user)
        } catch (error) {
          console.error(error)
          saveAuth(undefined)
          setLoading(false)
        }
      }
    }
    authAndSetUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]) // El segundo argumento de 'useEffect' es una lista de dependencias

  return (
    // <form
    //   className='form w-100'
    //   onSubmit={formik.handleSubmit}
    //   noValidate
    //   id='kt_login_signin_form'
    // >
    <div className='d-flex flex-column flex-center w-100'>
      {/* begin::Logo */}
      <Link to='/' className='mb-10'>
        {/* <span className='fs-2x text-white fw-bolder'>mydreamITjob.com</span> */}
        <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-dark.png')} className='h-70px' />
      </Link>
      {/* end::Logo */}

      {/* begin::Heading */}
      <div className='text-center'>
        <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({id: 'Login.SignInHeading'})}</h1>
        <div className='text-dark-500 fw-medium fs-6 text-justify mb-6'>{intl.formatMessage({id: 'Login.SignInDescription'})}</div>

        {/* begin::Heading */}

        {/* begin::Login options */}
        <div className='row g-3 mb-5'>
          {/* begin::Col */}
          <div className='col-md-12'>
            {/* begin::LinkedIn link */}
            <div
              style={{
                backgroundColor: '#003f67',
                color: 'white',
              }}
              className='btn btn-flex btn-outline 
            bg-state-dark 
            flex-center text-nowrap w-100'
              onClick={linkedInLogin}
            >
              <img src={toAbsoluteUrl('/media/svg/brand-logos/linkedin-3.svg')} alt='Sign in with Linked In' className='h-15px me-3' />
              {!loading && <span className='indicator-label'> {intl.formatMessage({id: 'Login.SignInWithLinkedIn'})}</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'Login.PleaseWait'})}

                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </div>
            {/* end::LinkedIn link */}
          </div>
          {/* end::Col */}
          {/* LinkedIn Ouath2.0 token retrieve */}

          {errorMessage && (
            <div className='mb-10 bg-light-danger p-8 rounded'>
              <div className='text-danger'>{errorMessage}</div>
            </div>
          )}
        </div>
        {/* end::Login options */}

        <div className='d-none d-lg-block text-gray-500 fw-bold fs-8 text-justify'>{intl.formatMessage({id: 'Login.EncryptionNotice'})}</div>

        {/* begin::Separator */}
        {/* <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div> */}
        {/* end::Separator */}

        {/* begin::Form group */}
        {/* <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div> */}
        {/* end::Form group */}

        {/* begin::Form group */}
        {/* <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div> */}
        {/* end::Form group */}

        {/* begin::Wrapper */}
        {/* <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div /> */}

        {/* begin::Link */}
        {/* <Link to='/auth/forgot-password' className='link-primary'>
          Forgot Password ?
        </Link> */}
        {/* end::Link */}
        {/* </div> */}
        {/* end::Wrapper */}

        {/* begin::Action */}
        {/* <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div> */}
        {/* end::Action */}

        {/* <div className='text-gray-500 text-center fw-semibold fs-6'>
        Not a Member yet?{' '}
        <Link to='/auth/registration' className='link-primary'>
          Sign up
        </Link>
      </div> */}
      </div>
    </div>
    // </form>
  )
}
