/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'

const AuthLayout = () => {
  const intl = useIntl()

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-30 p-10 order-1 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-none d-lg-flex flex-center flex-wrap px-5 mt-auto'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='/contact-us' className='px-5' target='_blank'>
              {intl.formatMessage({id: 'AuthLayout.ContactUs'})}
            </a>
            <a href='/faq' className='px-5' target='_blank'>
              {intl.formatMessage({id: 'AuthLayout.FAQ'})}
            </a>
            <a href='/privacy-policy' className='px-5' target='_blank'>
              {intl.formatMessage({id: 'AuthLayout.PrivacyPolicy'})}
            </a>
            <a href='/terms-and-conditions' className='px-5' target='_blank'>
              {intl.formatMessage({id: 'AuthLayout.TermsAndConditions'})}
            </a>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-2'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          {/* begin::Image */}
          <img className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20' src={toAbsoluteUrl('media/illustrations/freepik/iconic_25_01.png')} alt='' />
          {/* end::Image */}

          {/* begin::Title */}
          <h1 className='text-white fw-bolder text-center'>
            <span className='text-dark bg-white p-1'>{intl.formatMessage({id: 'AuthLayout.OnlyReceiveJobOffers'})}</span>{' '}
            {intl.formatMessage({id: 'AuthLayout.MatchingPreferences'})}
          </h1>
          {/* <h1 className='text-white fw-bold text-center mb-5'>Unlock your dream IT Job</h1>
          <h3 className='text-white fs-6 fw-bold text-center mb-10'>
            SignUp <a className='opacity-75-hover text-warning fw-bold me-1'>{'>'}</a> Job skills and preferences definition{' '}
            <a className='opacity-75-hover text-warning fw-bold me-1'>{'>'}</a> Perfect match{' '}
            <a className='opacity-75-hover text-warning fw-bold me-1'>(less than 5 mins)</a>
          </h3> */}
          {/* end::Title */}

          {/* begin::Text */}
          <div className='text-white fw-medium fs-6 text-center mt-10' style={{width: '75%'}}>
            <div className='mb-5'>
              <div className='symbol symbol-30px me-3'>
                <div className='symbol-label fw-bold text-primary'>1</div>
              </div>
              <span className='text-warning fw-bold me-1'>{intl.formatMessage({id: 'AuthLayout.CreateAccount'})}</span>{' '}
              {intl.formatMessage({id: 'AuthLayout.LinkingLinkedInProfile'})}
            </div>
            <div className='mb-5'>
              <div className='symbol symbol-30px me-3'>
                <div className='symbol-label fw-bold text-primary'>2</div>
              </div>
              {intl.formatMessage({id: 'AuthLayout.DetailJobSkills'})}{' '}
              <span className='text-warning fw-bold me-1'>{intl.formatMessage({id: 'AuthLayout.JobSkillsAndPreferences'})}</span>{' '}
              {intl.formatMessage({id: 'AuthLayout.DetermineTypeOfWork'})}
              <div className='mt-5 fs-7'>
                {/* For mobile devices */}
                <div className='d-lg-none'>
                  <code className='p-2 mb-1 d-block'>{intl.formatMessage({id: 'AuthLayout.ProgrammingLanguages'})}</code>
                  <code className='p-2 mb-1 d-block'>{intl.formatMessage({id: 'AuthLayout.MinimumSalary'})}</code>
                  <code className='p-2 mb-1 d-block'>{intl.formatMessage({id: 'AuthLayout.WorkModality'})}</code>
                </div>

                {/* For larger devices */}
                <div className='d-none d-lg-block'>
                  <code className='p-2'>{intl.formatMessage({id: 'AuthLayout.ProgrammingLanguages'})}</code>
                  <code className='p-2'>{intl.formatMessage({id: 'AuthLayout.MinimumSalary'})}</code>
                  <code className='p-2'>{intl.formatMessage({id: 'AuthLayout.WorkModality'})}</code>
                </div>
              </div>
            </div>
            <div>
              <div className='symbol symbol-30px me-3'>
                <div className='symbol-label fw-bold text-primary'>3</div>
              </div>
              <span className='text-warning fw-bold me-1'>{intl.formatMessage({id: 'AuthLayout.ReceivingJobOffers'})}</span>{' '}
              {intl.formatMessage({id: 'AuthLayout.EnsuringPerfectMatch'})}
            </div>
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
    </div>
  )
}

export {AuthLayout}
