import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL_LOGIN_WITH_LINKEDIN = `${process.env.REACT_APP_API_URL_LOGIN_WITH_LINKEDIN}`
const API_URL_GET_CANDIDATE_BY_TOKEN = `${process.env.REACT_APP_API_URL_GET_CANDIDATE_BY_TOKEN}`
const API_URL_POST_CANDIDATE_BY_TOKEN = `${process.env.REACT_APP_API_URL_POST_CANDIDATE_BY_TOKEN}`

// Linkedin server should return AuthModel
export function loginWithLinkedin(authorization_code: string, redirect_uri: string) {
  console.log('api loginWithLinkedin')
  return axios.post<AuthModel>(API_URL_LOGIN_WITH_LINKEDIN, {
    authorization_code,
    redirect_uri,
  })
}

export function getUserByToken(token: string) {
  console.log('api getUserByToken')
  return axios.post<UserModel>(API_URL_GET_CANDIDATE_BY_TOKEN, {
    api_token: token,
  })
}

const API_URL = process.env.REACT_APP_API_URL
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
// export function postUserByToken(token: string, user: UserModel) {
//   console.log('api postUserByToken')
//   return axios.post(API_URL_POST_CANDIDATE_BY_TOKEN, {api_token: token, user: user})
// }

export async function postUserByToken(token: string, user: UserModel) {
  console.log('api postUserByToken')
  return axios.post(API_URL_POST_CANDIDATE_BY_TOKEN, {api_token: token, user: user})
}

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(email: string, firstname: string, lastname: string, password: string, password_confirmation: string) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  console.log('api requestPassword')
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}
