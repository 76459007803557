/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {Link} from 'react-router-dom'

const ContactUsPage: FC = () => {
  return (
    <div className='docs-wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
      <div id='kt_docs_header' className='docs-header align-items-stretch mb-2 mb-lg-10'>
        <div className='container'>
          <div className='d-flex align-items-stretch justify-content-between py-3 h-75px'>
            <div className='d-flex d-lg-none align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'></div>
            <div className='d-flex align-items-center justify-content-between flex-lg-grow-1'>
              <div className='d-flex align-items-center' id='kt_docs_header_title'>
                <div className='d-flex align-items-center'></div>{' '}
                <Link to='/' className='my-1'>
                  <img alt='Logo' src={toAbsoluteUrl('/media/logos/favicon.png')} className='h-30px align-self-center' />
                </Link>
                <div className='docs-page-title d-flex flex-column flex-lg-row align-items-lg-center py-5 mb-lg-0 mx-4'>
                  <Link to='/contact-us'>
                    <h1 className='d-flex align-items-center text-gray-900 my-1 fs-4 fw-bold'>Contact Us</h1>
                  </Link>
                  <span className='d-none d-lg-block bullet h-20px w-1px bg-secondary mx-4'></span>
                  <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-6 my-1'>
                    <li className='breadcrumb-item text-gray-60'>
                      <span className='bullet bg-gray-200 w-5px h-2px'></span>
                    </li>
                    <li className='breadcrumb-item mr-2'>
                      <Link to='/contact-us' className='fw-bolder'>
                        Contact Us
                      </Link>
                    </li>
                    <li className='breadcrumb-item mx-2'>
                      <Link to='/faq'>FAQ</Link>
                    </li>
                    <li className='breadcrumb-item mx-2'>
                      <Link to='/privacy-policy'>Privacy Policy</Link>
                    </li>
                    <li className='breadcrumb-item ml-2'>
                      <Link to='/terms-and-conditions'>Terms and Conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className='d-flex align-items-center'>
                <a href='/' className='btn btn-primary btn-flex h-40px border-0 fw-bold px-4 px-lg-6'>
                  <i className='bi bi-arrow-return-left fs-4 me-2 fw-bold'></i> Back to main page
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='kt_docs_content' className='docs-content d-flex flex-column flex-column-fluid'>
        <div className='container' id='kt_docs_content_container'>
          <div className='card mb-2'>
            <div className='card-body fs-6 py-15 px-10 py-lg-15 px-lg-15'>
              <h1 className='anchor fw-bolder mb-5' id='overview'>
                Terms and Conditions
              </h1>
              <div className='py-4'>If you have any questions or need assistance, please reach out to us at one of the following email addresses:</div>

              <div className='py-4'>
                <h2>General Inquiries</h2>
                <p>
                  <strong>Email:</strong> <a href='mailto:contact@mydreamitjob.com'>contact@mydreamitjob.com</a>
                  <br />
                  Use this email for general questions about our services, feedback, or any other inquiries you might have.
                </p>
              </div>
              <div className='py-4'>
                <h2>Data Protection</h2>
                <p>
                  <strong>Email:</strong> <a href='mailto:dpo@mydreamitjob.com'>dpo@mydreamitjob.com</a>
                  <br />
                  Contact our Data Protection Officer for any questions related to privacy policies, data protection and your personal data rights.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export {ContactUsPage}
