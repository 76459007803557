import {FC, useEffect} from 'react'
import {Routes, Route, Navigate, useLocation} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {LinkedInCallback} from 'react-linkedin-login-oauth2'
import {TermsAndConditionsPage} from '../pages/TermsAndConditionsPage'
import {MenuTestPage} from '../pages/MenuTestPage'
import {PrivacyPolicyPage} from '../pages/PrivacyPolicyPage'
import {ContactUsPage} from '../pages/ContactUsPage'
import {FAQPage} from '../pages/FAQPage'
import ReactGA from 'react-ga4'

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  const location = useLocation()

  useEffect(() => {
    // Enviar información de la página actual a Google Analytics
    ReactGA.send({hitType: 'pageview', page: location.pathname + location.search})
  }, [location])

  return (
    <Routes>
      <Route path='/linkedin' element={<LinkedInCallback />} />
      <Route element={<App />}>
        <Route path='error/*' element={<ErrorsPage />} />
        <Route path='logout' element={<Logout />} />
        {currentUser ? (
          <>
            <Route path='/*' element={<PrivateRoutes />} />
            <Route index element={<Navigate to='/user/job-matches' />} />
          </>
        ) : (
          <>
            <Route path='/' element={<AuthPage />} />
            <Route path='/terms-and-conditions' element={<TermsAndConditionsPage />} />
            <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
            <Route path='/contact-us' element={<ContactUsPage />} />
            <Route path='/faq' element={<FAQPage />} />
            <Route path='/test' element={<MenuTestPage />} />

            <Route path='*' element={<Navigate to='/' />} />
          </>
        )}
      </Route>
    </Routes>
  )
}

export {AppRoutes}
