/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {Link} from 'react-router-dom'

const PrivacyPolicyPage: FC = () => {
  return (
    <div className='docs-wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
      <div id='kt_docs_header' className='docs-header align-items-stretch mb-2 mb-lg-10'>
        <div className='container'>
          <div className='d-flex align-items-stretch justify-content-between py-3 h-75px'>
            <div className='d-flex d-lg-none align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'></div>
            <div className='d-flex align-items-center justify-content-between flex-lg-grow-1'>
              <div className='d-flex align-items-center' id='kt_docs_header_title'>
                <div className='d-flex align-items-center'></div>{' '}
                <Link to='/' className='my-1'>
                  <img alt='Logo' src={toAbsoluteUrl('/media/logos/favicon.png')} className='h-30px align-self-center' />
                </Link>
                <div className='docs-page-title d-flex flex-column flex-lg-row align-items-lg-center py-5 mb-lg-0 mx-4'>
                  <Link to='/privacy-policy'>
                    <h1 className='d-flex align-items-center text-gray-900 my-1 fs-4 fw-bold'>Privacy Policy</h1>
                  </Link>
                  <span className='d-none d-lg-block bullet h-20px w-1px bg-secondary mx-4'></span>
                  <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-6 my-1'>
                    <li className='breadcrumb-item text-gray-60'>
                      <span className='bullet bg-gray-200 w-5px h-2px'></span>
                    </li>
                    <li className='breadcrumb-item mr-2'>
                      <Link to='/contact-us'>Contact Us</Link>
                    </li>
                    <li className='breadcrumb-item mx-2'>
                      <Link to='/faq'>FAQ</Link>
                    </li>
                    <li className='breadcrumb-item mx-2'>
                      <Link to='/privacy-policy' className='fw-bolder'>
                        Privacy Policy
                      </Link>
                    </li>
                    <li className='breadcrumb-item ml-2'>
                      <Link to='/terms-and-conditions'>Terms and Conditions</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className='d-flex align-items-center'>
                <a href='/' className='btn btn-primary btn-flex h-40px border-0 fw-bold px-4 px-lg-6'>
                  <i className='bi bi-arrow-return-left fs-4 me-2 fw-bold'></i> Back to main page
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='kt_docs_content' className='docs-content d-flex flex-column flex-column-fluid'>
        <div className='container' id='kt_docs_content_container'>
          <div className='card mb-2'>
            <div className='card-body fs-6 py-15 px-10 py-lg-15 px-lg-15'>
              <h1 className='anchor fw-bolder mb-5' id='overview'>
                Terms and Conditions
              </h1>
              <div className='py-4'>
                <h2>Privacy Policy</h2>
                <p>
                  At MYDREAMITJOB, your privacy is paramount. This policy outlines what personal data we collect, why we collect it, and how we handle it as data
                  controllers.
                </p>
                <p>We are committed to connecting candidates and companies to foster potential professional collaborations securely and respectfully.</p>
              </div>

              <div className='py-4'>
                <h2>1. Purpose and Legal Basis for Processing Personal Data</h2>
                <p>
                  All personal data we collect is essential for the services described in this policy. Without this data, we may not be able to provide you with our
                  services. We regularly request that you review and update your personal data to ensure it remains accurate and current.
                </p>
                <p>
                  On our Website, you will find information useful in understanding the services offered by MYDREAMITJOB and how to utilize our platform effectively. We
                  process your data only as necessary for the responses you request from us or to manage and maintain a business, contractual, or pre-contractual
                  relationship with you.
                </p>
                <p>
                  Below, we detail the specific purposes for which your personal data will be processed by MYDREAMITJOB, along with the lawful bases for these activities:
                </p>
                <ul>
                  <li>
                    <strong>For Recruiters:</strong> We process personal data to manage and develop your interactions through our platform, manage your registrations,
                    develop your user profiles, and manage your job offers and candidate interactions.
                  </li>
                  <li>
                    <strong>For Candidates:</strong> We handle personal data to manage your registration requests, develop your profiles to be shared with potential
                    employers, manage your application processes, and send you relevant job offers.
                  </li>
                </ul>
              </div>
              <div className='py-4'>
                <h2>2. Third Parties with Whom We Share Your Data</h2>
                <p>
                  Your personal data may be shared with other users of our platform solely for the purpose of providing you with the requested services, as outlined in
                  the section "1. Purpose and Legal Basis for Processing Personal Data".
                </p>
                <p>
                  Additionally, we may disclose your personal data to public authorities to comply with legal obligations or to third-party service providers who assist
                  us in our operations. These providers are carefully selected and obligated under contract to protect the confidentiality and security of your data.
                </p>
                <p>
                  International transfers of personal data may occur to countries outside the European Economic Area. In such cases, we ensure that appropriate safeguards
                  are in place to protect your data, compliant with applicable data protection laws.
                </p>
              </div>

              <div className='py-4'>
                <h2>3. How We Protect Your Personal Data</h2>
                <p>
                  At MYDREAMITJOB, we implement robust technical and organizational measures to protect your personal data against unauthorized or unlawful access,
                  alteration, disclosure, accidental loss, destruction, or damage.
                </p>
                <p>
                  We continuously train our staff involved in data processing to ensure the security and confidentiality of your personal data. We also maintain physical,
                  electronic, and procedural safeguards to protect your information.
                </p>
                <p>Data security is a top priority at MYDREAMITJOB, and we are committed to safeguarding your information and ensuring its confidentiality.</p>
              </div>
              <div className='py-4'>
                <h2>4. How Long Do We Keep Your Data?</h2>
                <p>
                  We retain your personal data for as long as necessary to fulfill the purposes outlined in this privacy policy, or as required by law, such as for tax
                  and accounting purposes. This period may extend beyond the end of your relationship with us but only for as long as necessary to fulfill legal, audit,
                  and compliance obligations.
                </p>
                <p>Once your personal data is no longer required, or when the retention period expires, we ensure that your data is securely deleted or anonymized.</p>
              </div>

              <div className='py-4'>
                <h2>5. Your Rights and How to Exercise Them</h2>
                <p>
                  You have several rights regarding your personal data under applicable law. These include the right to access, correct, delete, or restrict the
                  processing of your data. You also have the right to object to the processing of your data, the right to data portability, and the right not to be
                  subject to decisions based solely on automated processing.
                </p>
                <p>
                  To exercise any of these rights, please contact us at dpo@mydreamitjob.com. We will respond to your requests within the applicable legal timeframes.
                </p>
                <p>
                  If you believe that your data protection rights have been violated, you have the right to lodge a complaint with the relevant data protection authority.
                  In Spain, this is the Spanish Data Protection Agency (www.aepd.es).
                </p>
                <p>
                  You can withdraw any consents you have given us at any time by contacting us using the details provided. This will not affect the lawfulness of any
                  processing carried out before you withdraw your consent.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export {PrivacyPolicyPage}
