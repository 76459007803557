import {JobMatchesTable} from '../../widgets/JobMatchesTable'
import {useIntl} from 'react-intl'

export function JobMatchesDetails() {
  const intl = useIntl()

  return (
    <>
      <div>
        <div className='card mb-5 mb-xl-10'>
          <div
            className='card-header border-0 cursor-pointer'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_account_profile_details'
            aria-expanded='true'
            aria-controls='kt_account_profile_details'
          >
            <div className='card-title m-0'>
              <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'JobMatchesDetails.Form.Title'})}</h3>
            </div>
          </div>

          <div id='kt_account_profile_details' className='collapse show'>
            <div className='card-body border-top p-9'>
              <p className='fw-semibold fs-6 mb-6'>{intl.formatMessage({id: 'JobMatchesDetails.Form.Description'})}</p>
              <JobMatchesTable className='mb-5 mb-xl-8' />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
