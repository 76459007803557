import {UserProfessionalProfileModel} from '../../../../interfaces/_models'

export const initialValues: UserProfessionalProfileModel = {
  experience: {
    values: {
      years: 0,
    },
  },
  education: {
    values: {
      level: '',
    },
  },
  programming_languages: {
    values: {},
  },
  positions: {
    values: {},
  },
  technologies: {
    values: {},
  },
  industries: {
    values: {},
  },
}
