/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {Link} from 'react-router-dom'

const TermsAndConditionsPage: FC = () => {
  return (
    <div className='docs-wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
      <div id='kt_docs_header' className='docs-header align-items-stretch mb-2 mb-lg-10'>
        <div className='container'>
          <div className='d-flex align-items-stretch justify-content-between py-3 h-75px'>
            <div className='d-flex d-lg-none align-items-center flex-grow-1 flex-lg-grow-0 me-lg-15'></div>
            <div className='d-flex align-items-center justify-content-between flex-lg-grow-1'>
              <div className='d-flex align-items-center'>
                <Link to='/terms-and-conditions' className='my-1'>
                  <img alt='Logo' src={toAbsoluteUrl('/media/logos/favicon.png')} className='h-30px align-self-center' />
                </Link>
                <div className='docs-page-title d-flex flex-column flex-lg-row align-items-lg-center py-5 mb-lg-0 mx-4'>
                  <Link to='/faq'>
                    <h1 className='d-flex align-items-center text-gray-900 my-1 fs-4 fw-bold'>Terms and Conditions</h1>
                  </Link>
                  <span className='d-none d-lg-block bullet h-20px w-1px bg-secondary mx-4'></span>
                  <ul className='breadcrumb breadcrumb-separatorless fw-bold fs-6 my-1'>
                    <li className='breadcrumb-item text-gray-60'>
                      <span className='bullet bg-gray-200 w-5px h-2px'></span>
                    </li>
                    <li className='breadcrumb-item mr-2'>
                      <Link to='/contact-us'>Contact Us</Link>
                    </li>
                    <li className='breadcrumb-item mx-2'>
                      <Link to='/faq'>FAQ</Link>
                    </li>
                    <li className='breadcrumb-item mx-2'>
                      <Link to='/privacy-policy'>Privacy Policy</Link>
                    </li>
                    <li className='breadcrumb-item ml-2'>
                      <Link to='/terms-and-conditions' className='fw-bolder'>
                        Terms and Conditions
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className='d-flex align-items-center'>
                <Link to='/' className='btn btn-primary btn-flex h-40px border-0 fw-bold px-4 px-lg-6'>
                  <i className='bi bi-arrow-return-left fs-4 me-2 fw-bold'></i> Back to main page
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='kt_docs_content' className='docs-content d-flex flex-column flex-column-fluid'>
        <div className='container' id='kt_docs_content_container'>
          <div className='card mb-2'>
            <div className='card-body fs-6 py-15 px-10 py-lg-15 px-lg-15' style={{textAlign: 'justify'}}>
              <h1 className='anchor fw-bolder mb-5' id='overview'>
                Terms and Conditions
              </h1>
              <div className='py-4'>
                <h2>1. General Information</h2>
                <p>
                  1.1. MYDREAMITJOB.COM (hereinafter "MYDREAMITJOB") is the owner of the website <a href='https://mydreamitjob.com/'>https://mydreamitjob.com/</a>{' '}
                  (hereinafter the "Website").
                </p>
                <p>
                  1.2. These terms and conditions (hereinafter the "T&C") regulate the access, navigation and use of the Website as well as the service by which
                  MYDREAMITJOB makes available to users and companies a digital platform (hereinafter the "Platform"), intended for any natural or legal person, through
                  which they can exchange applications, creating a private community that allows companies from all sectors to improve the management of their selection
                  processes, thus facilitating the participation of candidates from the Platform in various selection processes and their subsequent recruitment
                  (hereinafter the "Service").
                </p>
                <p>
                  1.3. Anyone who makes use, in any of the possible ways, of the Website and/or the Platform may be considered a user (hereinafter "User"). In this sense,
                  a User may be: (i) an entity accessing the Platform to search for candidates or to use any of the modalities of the Service offered by MYDREAMITJOB
                  through the Platform (hereinafter "Recruiter"); (ii) an individual in search of a job position through any of the forms established in the Platform
                  (hereinafter "Candidate") and (iii) any person accessing, using or browsing the Website.
                </p>
                <p>
                  1.4. These T&C are available to all Users of the Website and / or the Platform at the following link{' '}
                  <a href='https://mydreamitjob.com/terms-and-conditions/'>https://mydreamitjob.com/terms-and-conditions/</a>
                  freely and free of charge.
                </p>
                <p>
                  1.5. In case you complete the registration process on the Platform, checking the box of acceptance of these T&C during registration on the Platform
                  implies the hiring of the Service in accordance with the T&C. Similarly, the User must have read the Privacy Policy that applies (Web Privacy Policy or
                  Privacy Policy Candidates) available at <a href='https://mydreamitjob.com/privacy-policy/'>https://mydreamitjob.com/privacy-policy/</a> before
                  proceeding with the registration on the Platform.
                </p>
                <p>
                  1.6. Also, access, navigation and use of the Website also implies the express and unreserved acceptance of the T&C, to the extent applicable, having the
                  same validity and effectiveness as any written and signed contract. Their observance and compliance shall be enforceable with respect to any User who
                  accesses, uses or browses the Website. If you do not agree with the T&C, do not access, browse or use the Website.
                </p>
                <p>
                  1.7. In case the User wants to contact MYDREAMITJOB for any doubt or incident regarding the Website and/or regarding the registration on the Platform or
                  the service it entails, he/she may contact MYDREAMITJOB through the following email address contact@mydreamitjob.com .
                </p>
              </div>
              <div className='py-4'>
                <h2>2. Platform Registration Process</h2>
                <p>
                  2.1. Registration on the Platform through the Website is prohibited both for those individuals who do not have full legal capacity, and for minors under
                  18 years of age who do not have the proper authorization of their parents and / or legal representatives, except in case of emancipation. Registration
                  on the Website shall be completely free of charge, except for the cost of connection through the telecommunications network provided by the access
                  provider contracted by the User.
                </p>
                <p>2.2. It is forbidden to use or attempt to use another person's account or to create a false identity on the Platform.</p>
                <p>
                  2.3. In the event that the registration process is initiated on the Platform, the registration process differs depending on whether the User is a
                  Recruiter or a Candidate:
                </p>
                <p>
                  In case of being a Recruiter, to register on the Platform the User must access the section "Registration as a startup" enabled on the Website and
                  provide certain information accurately and completely in order to create your profile within the Platform. To do this, the User must confirm that you
                  have read the Privacy Policy and accept these T&C.
                </p>
                <p>
                  By registering, the Recruiter - who accesses through a natural person - declares and guarantees that he/she is authorized to accept these T&Cs on behalf
                  of the entity he/she represents and that, therefore, the latter agrees to assume responsibility for the use of the Platform and the Service in
                  compliance with these T&Cs, as well as to indemnify MYDREAMITJOB for the violation of any aspect of the same.
                </p>
                <p>
                  In case of being a Candidate, to register on the Platform the User must access the section "Registration as a candidate" and provide certain information
                  accurately and completely in order to create your profile within the Platform. To do this, the User must confirm that you have read the Privacy Policy
                  and accept these T&C.
                </p>
                <p>
                  2.4. The password chosen during the registration process will be personal and non-transferable, not being allowed the transfer, even temporarily, to
                  third parties. In this sense, the User agrees to make diligent use and keep secret the password chosen, if any, to access your profile on the Platform
                  and the Service provided. In the event that the User knows or suspects the loss, theft or use of your password by third parties, you should bring such
                  circumstances to the attention of MYDREAMITJOB as soon as possible.
                </p>
                <p>
                  2.5. Each User shall be liable for the costs and damages caused by the use of the services by any third party who uses its password for this purpose as
                  a result of improper or negligent use or as a result of the loss of the same by the User.
                </p>
                <p>
                  2.6. If the User makes a mistake in entering data during the hiring of the Service offered on the Platform, this can be identified and corrected by the
                  User before completing the registration, or after having done so, by sending an email to contact@mydreamitjob.com. Please note that it is important that
                  you check the information you enter and correct any errors before finalizing your registration.
                </p>
              </div>

              <div className='py-4'>
                <h2>3. Use of the Platform</h2>
                <p>3.1. License on the contents published on the Platform:</p>
                <p>
                  The Recruiter is the owner of the content and information provided or published on the Platform, including but not limited to, job offers and references
                  made to Candidates, granting MYDREAMITJOB a non-exclusive, non-transferable, free and global geographic license to use, copy, modify, publish and
                  process the information provided through the Platform. The Recruiter may terminate this license by deleting such content from the Platform or by
                  deleting its User account, unless (i) it has shared it with other Users as part of the Service and they in turn have copied, shared or stored it; and
                  (ii) during the reasonable time it takes to remove it from backup copies and other systems.
                </p>
                <p>3.2. Candidate Services:</p>
                <ul>
                  <li>
                    Management of the registration on the Platform, in response to the User's request sent to MYDREAMITJOB or as a consequence of the User's acceptance of
                    the invitation to register on the Platform.
                  </li>
                  <li>
                    Creation, development, and publication of the Candidate profile. The information collected in the profile shall be accessible only by Users registered
                    on the Platform and shall be processed by MYDREAMITJOB in accordance with the provisions of our Privacy Policy.
                  </li>
                  <li>
                    Management and publication of references granted to the Candidate by Recruiters or third parties not registered on the Platform, following the
                    Candidate's request made through the use of the "Request references" functionality.
                  </li>
                  <li>
                    Management and publication of referrals made by the Candidate to other Candidates by making a referral link available to them; or by sending the
                    referral email through the Platform on behalf of the Candidate.
                  </li>
                  <li>
                    Sending and management of satisfaction surveys in order to know the Candidate's feedback about the Recruiters with whom he/she carried out the
                    selection processes. Surveys can be sent via e-mail or SMS.
                  </li>
                  <li>Personalized advice service on the use of the Platform, through instant chat with our team.</li>
                  <li>Messaging service through the Platform, mainly to contact Recruiters and our team.</li>
                  <li>
                    Automatic and free receipt of offers electronically. MYDREAMITJOB offers the possibility of knowing through electronic communications (email, SMS,
                    message on LinkedIn or WhatsApp) the job offers that best suit the Candidate.
                  </li>
                  <li>
                    Other services that MYDREAMITJOB may create and that it considers of interest to the Candidates such as advice, training, professional contact
                    network, dialogue spaces, access to news, among others.
                  </li>
                </ul>
                <p>3.3. Use of the Platform by Recruiters:</p>
                <ul>
                  <li>
                    Management of registration on the Platform and creation, development and publication of the Recruiter profile. The information collected in the
                    profile will be treated in accordance with the provisions of our Privacy Policy and may be accessed by all Users, including those not registered on
                    the Platform.
                  </li>
                  <li>Design and publication of job offers and/or creation of groups for the dissemination of offers of interest to its members.</li>
                  <li>
                    Support in the management and selection of candidates proposed by the Website based on the requirements of the offer by sending an invitation to
                    interview or discarding the profile.
                  </li>
                  <li>
                    Management and development of a recognition program for Recruiters. Specifically, MYDREAMITJOB awards Recruiters recognition points for reaching a
                    series of milestones such as account activation or making a reference to the Candidate, among others. The points earned by the Recruiter can be
                    exchanged for the different prizes offered by MYDREAMITJOB to recognize those Recruiters with the highest activity on the Platform.
                  </li>
                  <li>
                    Management of the integration of the Platform with the Applicant Tracking System (ATS) of the Entity in such a way that any progress of the Candidates
                    on the Platform is reflected in MYDREAMITJOB shall only have access to the information strictly necessary to be able to carry out the correct
                    monitoring of the selection processes of the users registered on the Platform.
                  </li>
                </ul>
              </div>
              <div className='py-4'>
                <h2>4. Termination of registration on the Platform</h2>
                <p>
                  4.1. In the event that the registration process on the Platform is completed, the contract between MYDREAMITJOB and the User, whether Candidate or
                  Recruiter, is perfected from the moment the latter completes the registration procedure on the Platform, it being understood that the completion of the
                  registration process and the inclusion of all the requested data, together with the ticking of the corresponding box relating to the acceptance of these
                  T&C, is a direct manifestation of having read them and of the User's willingness to accept them.
                </p>
                <p>4.2. MYDREAMITJOB shall keep evidence of the Candidate's or Recruiter's acceptance of these T&C.</p>
                <p>
                  4.3. The language in which the registration and recruitment procedure will be processed and in which this contract is formalized shall be, unless
                  otherwise indicated, Spanish.
                </p>
              </div>

              <div className='py-4'>
                <h2>5. Procedure for unsubscribing from the Platform</h2>
                <p>
                  5.1. If the user wishes to unsubscribe from the platform, he/she should send an email to contact@mydreamitjob.com. MYDREAMITJOB will unsubscribe the
                  account in less than 24 hours.
                </p>
              </div>
              <div className='py-4'>
                <h2>6. Accuracy of the User's information and declarations</h2>
                <p>
                  6.1. In the event that the User completes the registration process on the Platform, the User guarantees that the data and information provided are
                  truthful and do not contain errors. Under no circumstances shall MYDREAMITJOB be responsible for the truthfulness and accuracy of the data and
                  information provided by the User, being the User responsible for any possible consequences, errors and failures that may subsequently arise from the
                  lack of truthfulness of such information in the provision of the Service.
                </p>
                <p>
                  6.2. In case of being a Candidate, the User acknowledges and agrees that any information transmitted through the Platform is subject to these T&C. In
                  this regard, the User is aware that both MYDREAMITJOB and the Recruiters registered on the Platform rely on the accuracy and completeness of all
                  information provided on the Platform, ensuring that all information included in their Platform profile is truthful, accurate and complete. Similarly, it
                  shall be the responsibility of the Candidate to keep all information provided to MYDREAMITJOB permanently updated so that it responds, at all times to
                  the actual situation of the Candidate In any case, the Candidate shall be solely responsible for any false or inaccurate statements made and the damages
                  caused to MYDREAMITJOB or third parties for the information provided.
                </p>
                <p>
                  6.3. In this regard, the User acknowledges that, by registering and creating his/her profile on the Platform, he/she is requesting and authorizing
                  MYDREAMITJOB to make his/her profile available to the different Recruiters depending on the selected geographical area.
                </p>
              </div>

              <div className='py-4'>
                <h2>7. Price of the Service provided through the Platform</h2>
                <p>
                  7.1. The Service provided through the Platform is completely free for the Candidate, so this will not have to pay any amount of money to enjoy the
                  Platform.
                </p>
                <p>
                  7.2. The Service provided through the Platform may have a cost for the Recruiter depending on the type of Service selected.
                  {/* You can check the updated rates for each mode at the following link: <a href='https://mydreamitjob.com/pricing'>https://mydreamitjob.com/pricing</a>. */}
                </p>
              </div>

              <div className='py-4'>
                <h2>8. Duration and Termination</h2>
                <p>
                  8.1. These T&C shall remain in force until such time as the User unsubscribes from the Platform or the termination of the Service for any of the reasons
                  stipulated in these T&C. Also, in the event that the User only accesses, uses or browses the Website without completing the registration process on the
                  Platform, these T&C shall apply while accessing, using or browsing the Website, to the extent applicable.
                </p>
                <p>
                  8.2. In the event that the registration process on the Platform is completed, the User may conclude the contract established through these T&C by
                  deactivating his account, immediately interrupting the provision of the Service.
                </p>
                <p>
                  8.3. Similarly, in the event that the registration process on the Platform is terminated, MYDREAMITJOB reserves the right to conclude the contract
                  established through these T&C by suspending or terminating the User's account, either temporarily or permanently, at any time and for any reason,
                  immediately interrupting the provision of the Service, such as: (i) breach of these T&C, (ii) the User causes MYDREAMITJOB to incur legal risk or
                  liability; (iii) the User's account must be terminated due to unlawful conduct; (iv) the User's account must be terminated due to the User's prolonged
                  inactivity; or (v) the provision of the Service is no longer commercially viable.
                </p>
                <p>
                  8.4. MYDREAMITJOB declares that it will make all reasonable efforts to notify the User through the email address associated with its account or profile
                  on the Platform, about the termination and interruption of the provision of the Service.
                </p>
              </div>
              <div className='py-4'>
                <h2>9. Responsibility for the Website, the contracting process and the provision of the Service</h2>
                <p>Both the User and MYDREAMITJOB undertake to comply with their legal and contractual obligations generated under these T&C.</p>
                <p>
                  MYDREAMITJOB shall not be liable in case of unavailability of the Website and/or the Service offered through the Platform when this is due to force
                  majeure, theft or loss or error in the information provided by the User.
                </p>
                <p>
                  MYDREAMITJOB shall use its best efforts to keep the Website and/or the Service available through the Platform, except for lack of availability or
                  performance of the same due to:
                </p>
                <ul>
                  <li>
                    Inactivity or temporary unavailability of the Platform due to updating and/or technical maintenance of the Platform, of which it will inform in
                    advance and as soon as possible.
                  </li>
                  <li>
                    Causes beyond MYDREAMITJOB's control, such as force majeure, Internet access problems, technological problems, actions or omissions of third parties,
                    etc.
                  </li>
                </ul>
                <p>
                  In all the aforementioned cases, beyond the control and diligence of MYDREAMITJOB, the User shall not be entitled to any compensation from MYDREAMITJOB
                  for damages, direct or indirect, or for lost profits. Likewise, MYDREAMITJOB shall not be liable for damages caused by third parties.
                </p>
                <p>
                  In the event of closure or suspension of the Platform and/or Website for reasons beyond the control of MYDREAMITJOB, and whenever possible, the User
                  shall be promptly informed of the transfer of the service to a new domain, modifying only the provisions of these T&C in relation to the domain where
                  the Platform remains active.
                </p>
                <p>
                  MYDREAMITJOB does not represent or vouch for any User, whether Candidate or Recruiter, and therefore the User guarantees to hold MYDREAMITJOB harmless
                  for any dispute or liability arising from the relationship between the Users.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export {TermsAndConditionsPage}
