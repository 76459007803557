export const _languages = [
  {value: '', label: 'Select a Language...'},
  {value: 'id', label: 'Bahasa Indonesia - Indonesian'},
  {value: 'msa', label: 'Bahasa Melayu - Malay'},
  {value: 'ca', label: 'Català - Catalan'},
  {value: 'cs', label: 'Čeština - Czech'},
  {value: 'da', label: 'Dansk - Danish'},
  {value: 'de', label: 'Deutsch - German'},
  {value: 'en', label: 'English'},
  {value: 'en-gb', label: 'English UK - British English'},
  {value: 'es', label: 'Español - Spanish'},
  {value: 'fil', label: 'Filipino'},
  {value: 'fr', label: 'Français - French'},
  {value: 'ga', label: 'Gaeilge - Irish (beta)'},
  {value: 'gl', label: 'Galego - Galician (beta)'},
  {value: 'hr', label: 'Hrvatski - Croatian'},
  {value: 'it', label: 'Italiano - Italian'},
  {value: 'hu', label: 'Magyar - Hungarian'},
  {value: 'nl', label: 'Nederlands - Dutch'},
  {value: 'no', label: 'Norsk - Norwegian'},
  {value: 'pl', label: 'Polski - Polish'},
  {value: 'pt', label: 'Português - Portuguese'},
  {value: 'ro', label: 'Română - Romanian'},
  {value: 'sk', label: 'Slovenčina - Slovak'},
  {value: 'fi', label: 'Suomi - Finnish'},
  {value: 'sv', label: 'Svenska - Swedish'},
  {value: 'vi', label: 'Tiếng Việt - Vietnamese'},
  {value: 'tr', label: 'Türkçe - Turkish'},
  {value: 'el', label: 'Ελληνικά - Greek'},
  {value: 'bg', label: 'Български език - Bulgarian'},
  {value: 'ru', label: 'Русский - Russian'},
  {value: 'sr', label: 'Српски - Serbian'},
  {value: 'uk', label: 'Українська мова - Ukrainian'},
  {value: 'he', label: 'עִבְרִית - Hebrew'},
  {value: 'ur', label: 'اردو - Urdu (beta)'},
  {value: 'ar', label: 'العربية - Arabic'},
  {value: 'fa', label: 'فارسی - Persian'},
  {value: 'mr', label: 'मराठी - Marathi'},
  {value: 'hi', label: 'हिन्दी - Hindi'},
  {value: 'bn', label: 'বাংলা - Bangla'},
  {value: 'gu', label: 'ગુજરાતી - Gujarati'},
  {value: 'ta', label: 'தமிழ் - Tamil'},
  {value: 'kn', label: 'ಕನ್ನಡ - Kannada'},
  {value: 'th', label: 'ภาษาไทย - Thai'},
  {value: 'ko', label: '한국어 - Korean'},
  {value: 'ja', label: '日本語 - Japanese'},
  {value: 'zh-cn', label: '简体中文 - Simplified Chinese'},
  {value: 'zh-tw', label: '繁體中文 - Traditional Chinese'},
]

export const _countries = [
  {value: '', label: 'Select a Country...'},
  {value: 'AF', label: 'Afghanistan'},
  {value: 'AX', label: 'Aland Islands'},
  {value: 'AL', label: 'Albania'},
  {value: 'DZ', label: 'Algeria'},
  {value: 'AS', label: 'American Samoa'},
  {value: 'AD', label: 'Andorra'},
  {value: 'AO', label: 'Angola'},
  {value: 'AI', label: 'Anguilla'},
  {value: 'AQ', label: 'Antarctica'},
  {value: 'AG', label: 'Antigua and Barbuda'},
  {value: 'AR', label: 'Argentina'},
  {value: 'AM', label: 'Armenia'},
  {value: 'AW', label: 'Aruba'},
  {value: 'AU', label: 'Australia'},
  {value: 'AT', label: 'Austria'},
  {value: 'AZ', label: 'Azerbaijan'},
  {value: 'BS', label: 'Bahamas'},
  {value: 'BH', label: 'Bahrain'},
  {value: 'BD', label: 'Bangladesh'},
  {value: 'BB', label: 'Barbados'},
  {value: 'BY', label: 'Belarus'},
  {value: 'BE', label: 'Belgium'},
  {value: 'BZ', label: 'Belize'},
  {value: 'BJ', label: 'Benin'},
  {value: 'BM', label: 'Bermuda'},
  {value: 'BT', label: 'Bhutan'},
  {value: 'BO', label: 'Bolivia, Plurinational State of'},
  {value: 'BQ', label: 'Bonaire, Sint Eustatius and Saba'},
  {value: 'BA', label: 'Bosnia and Herzegovina'},
  {value: 'BW', label: 'Botswana'},
  {value: 'BV', label: 'Bouvet Island'},
  {value: 'BR', label: 'Brazil'},
  {value: 'IO', label: 'British Indian Ocean Territory'},
  {value: 'BN', label: 'Brunei Darussalam'},
  {value: 'BG', label: 'Bulgaria'},
  {value: 'BF', label: 'Burkina Faso'},
  {value: 'BI', label: 'Burundi'},
  {value: 'KH', label: 'Cambodia'},
  {value: 'CM', label: 'Cameroon'},
  {value: 'CA', label: 'Canada'},
  {value: 'CV', label: 'Cape Verde'},
  {value: 'KY', label: 'Cayman Islands'},
  {value: 'CF', label: 'Central African Republic'},
  {value: 'TD', label: 'Chad'},
  {value: 'CL', label: 'Chile'},
  {value: 'CN', label: 'China'},
  {value: 'CX', label: 'Christmas Island'},
  {value: 'CC', label: 'Cocos (Keeling) Islands'},
  {value: 'CO', label: 'Colombia'},
  {value: 'KM', label: 'Comoros'},
  {value: 'CG', label: 'Congo'},
  {value: 'CD', label: 'Congo, the Democratic Republic of the'},
  {value: 'CK', label: 'Cook Islands'},
  {value: 'CR', label: 'Costa Rica'},
  {value: 'CI', label: "Côte d'Ivoire"},
  {value: 'HR', label: 'Croatia'},
  {value: 'CU', label: 'Cuba'},
  {value: 'CW', label: 'Curaçao'},
  {value: 'CY', label: 'Cyprus'},
  {value: 'CZ', label: 'Czech Republic'},
  {value: 'DK', label: 'Denmark'},
  {value: 'DJ', label: 'Djibouti'},
  {value: 'DM', label: 'Dominica'},
  {value: 'DO', label: 'Dominican Republic'},
  {value: 'EC', label: 'Ecuador'},
  {value: 'EG', label: 'Egypt'},
  {value: 'SV', label: 'El Salvador'},
  {value: 'GQ', label: 'Equatorial Guinea'},
  {value: 'ER', label: 'Eritrea'},
  {value: 'EE', label: 'Estonia'},
  {value: 'ET', label: 'Ethiopia'},
  {value: 'FK', label: 'Falkland Islands (Malvinas)'},
  {value: 'FO', label: 'Faroe Islands'},
  {value: 'FJ', label: 'Fiji'},
  {value: 'FI', label: 'Finland'},
  {value: 'FR', label: 'France'},
  {value: 'GF', label: 'French Guiana'},
  {value: 'PF', label: 'French Polynesia'},
  {value: 'TF', label: 'French Southern Territories'},
  {value: 'GA', label: 'Gabon'},
  {value: 'GM', label: 'Gambia'},
  {value: 'GE', label: 'Georgia'},
  {value: 'DE', label: 'Germany'},
  {value: 'GH', label: 'Ghana'},
  {value: 'GI', label: 'Gibraltar'},
  {value: 'GR', label: 'Greece'},
  {value: 'GL', label: 'Greenland'},
  {value: 'GD', label: 'Grenada'},
  {value: 'GP', label: 'Guadeloupe'},
  {value: 'GU', label: 'Guam'},
  {value: 'GT', label: 'Guatemala'},
  {value: 'GG', label: 'Guernsey'},
  {value: 'GN', label: 'Guinea'},
  {value: 'GW', label: 'Guinea-Bissau'},
  {value: 'GY', label: 'Guyana'},
  {value: 'HT', label: 'Haiti'},
  {value: 'HM', label: 'Heard Island and McDonald Islands'},
  {value: 'VA', label: 'Holy See (Vatican City State)'},
  {value: 'HN', label: 'Honduras'},
  {value: 'HK', label: 'Hong Kong'},
  {value: 'HU', label: 'Hungary'},
  {value: 'IS', label: 'Iceland'},
  {value: 'IN', label: 'India'},
  {value: 'ID', label: 'Indonesia'},
  {value: 'IR', label: 'Iran, Islamic Republic of'},
  {value: 'IQ', label: 'Iraq'},
  {value: 'IE', label: 'Ireland'},
  {value: 'IM', label: 'Isle of Man'},
  {value: 'IL', label: 'Israel'},
  {value: 'IT', label: 'Italy'},
  {value: 'JM', label: 'Jamaica'},
  {value: 'JP', label: 'Japan'},
  {value: 'JE', label: 'Jersey'},
  {value: 'JO', label: 'Jordan'},
  {value: 'KZ', label: 'Kazakhstan'},
  {value: 'KE', label: 'Kenya'},
  {value: 'KI', label: 'Kiribati'},
  {value: 'KP', label: "Korea, Democratic People's Republic of"},
  {value: 'KW', label: 'Kuwait'},
  {value: 'KG', label: 'Kyrgyzstan'},
  {value: 'LA', label: "Lao People's Democratic Republic"},
  {value: 'LV', label: 'Latvia'},
  {value: 'LB', label: 'Lebanon'},
  {value: 'LS', label: 'Lesotho'},
  {value: 'LR', label: 'Liberia'},
  {value: 'LY', label: 'Libya'},
  {value: 'LI', label: 'Liechtenstein'},
  {value: 'LT', label: 'Lithuania'},
  {value: 'LU', label: 'Luxembourg'},
  {value: 'MO', label: 'Macao'},
  {value: 'MK', label: 'Macedonia, the former Yugoslav Republic of'},
  {value: 'MG', label: 'Madagascar'},
  {value: 'MW', label: 'Malawi'},
  {value: 'MY', label: 'Malaysia'},
  {value: 'MV', label: 'Maldives'},
  {value: 'ML', label: 'Mali'},
  {value: 'MT', label: 'Malta'},
  {value: 'MH', label: 'Marshall Islands'},
  {value: 'MQ', label: 'Martinique'},
  {value: 'MR', label: 'Mauritania'},
  {value: 'MU', label: 'Mauritius'},
  {value: 'YT', label: 'Mayotte'},
  {value: 'MX', label: 'Mexico'},
  {value: 'FM', label: 'Micronesia, Federated States of'},
  {value: 'MD', label: 'Moldova, Republic of'},
  {value: 'MC', label: 'Monaco'},
  {value: 'MN', label: 'Mongolia'},
  {value: 'ME', label: 'Montenegro'},
  {value: 'MS', label: 'Montserrat'},
  {value: 'MA', label: 'Morocco'},
  {value: 'MZ', label: 'Mozambique'},
  {value: 'MM', label: 'Myanmar'},
  {value: 'NA', label: 'Namibia'},
  {value: 'NR', label: 'Nauru'},
  {value: 'NP', label: 'Nepal'},
  {value: 'NL', label: 'Netherlands'},
  {value: 'NC', label: 'New Caledonia'},
  {value: 'NZ', label: 'New Zealand'},
  {value: 'NI', label: 'Nicaragua'},
  {value: 'NE', label: 'Niger'},
  {value: 'NG', label: 'Nigeria'},
  {value: 'NU', label: 'Niue'},
  {value: 'NF', label: 'Norfolk Island'},
  {value: 'MP', label: 'Northern Mariana Islands'},
  {value: 'NO', label: 'Norway'},
  {value: 'OM', label: 'Oman'},
  {value: 'PK', label: 'Pakistan'},
  {value: 'PW', label: 'Palau'},
  {value: 'PS', label: 'Palestinian Territory, Occupied'},
  {value: 'PA', label: 'Panama'},
  {value: 'PG', label: 'Papua New Guinea'},
  {value: 'PY', label: 'Paraguay'},
  {value: 'PE', label: 'Peru'},
  {value: 'PH', label: 'Philippines'},
  {value: 'PN', label: 'Pitcairn'},
  {value: 'PL', label: 'Poland'},
  {value: 'PT', label: 'Portugal'},
  {value: 'PR', label: 'Puerto Rico'},
  {value: 'QA', label: 'Qatar'},
  {value: 'RE', label: 'Réunion'},
  {value: 'RO', label: 'Romania'},
  {value: 'RU', label: 'Russian Federation'},
  {value: 'RW', label: 'Rwanda'},
  {value: 'BL', label: 'Saint Barthélemy'},
  {value: 'SH', label: 'Saint Helena, Ascension and Tristan da Cunha'},
  {value: 'KN', label: 'Saint Kitts and Nevis'},
  {value: 'LC', label: 'Saint Lucia'},
  {value: 'MF', label: 'Saint Martin (French part)'},
  {value: 'PM', label: 'Saint Pierre and Miquelon'},
  {value: 'VC', label: 'Saint Vincent and the Grenadines'},
  {value: 'WS', label: 'Samoa'},
  {value: 'SM', label: 'San Marino'},
  {value: 'ST', label: 'Sao Tome and Principe'},
  {value: 'SA', label: 'Saudi Arabia'},
  {value: 'SN', label: 'Senegal'},
  {value: 'RS', label: 'Serbia'},
  {value: 'SC', label: 'Seychelles'},
  {value: 'SL', label: 'Sierra Leone'},
  {value: 'SG', label: 'Singapore'},
  {value: 'SX', label: 'Sint Maarten (Dutch part)'},
  {value: 'SK', label: 'Slovakia'},
  {value: 'SI', label: 'Slovenia'},
  {value: 'SB', label: 'Solomon Islands'},
  {value: 'SO', label: 'Somalia'},
  {value: 'ZA', label: 'South Africa'},
  {value: 'GS', label: 'South Georgia and the South Sandwich Islands'},
  {value: 'KR', label: 'South Korea'},
  {value: 'SS', label: 'South Sudan'},
  {value: 'ES', label: 'Spain'},
  {value: 'LK', label: 'Sri Lanka'},
  {value: 'SD', label: 'Sudan'},
  {value: 'SR', label: 'Suriname'},
  {value: 'SJ', label: 'Svalbard and Jan Mayen'},
  {value: 'SZ', label: 'Swaziland'},
  {value: 'SE', label: 'Sweden'},
  {value: 'CH', label: 'Switzerland'},
  {value: 'SY', label: 'Syrian Arab Republic'},
  {value: 'TW', label: 'Taiwan, Province of China'},
  {value: 'TJ', label: 'Tajikistan'},
  {value: 'TZ', label: 'Tanzania, United Republic of'},
  {value: 'TH', label: 'Thailand'},
  {value: 'TL', label: 'Timor-Leste'},
  {value: 'TG', label: 'Togo'},
  {value: 'TK', label: 'Tokelau'},
  {value: 'TO', label: 'Tonga'},
  {value: 'TT', label: 'Trinidad and Tobago'},
  {value: 'TN', label: 'Tunisia'},
  {value: 'TR', label: 'Turkey'},
  {value: 'TM', label: 'Turkmenistan'},
  {value: 'TC', label: 'Turks and Caicos Islands'},
  {value: 'TV', label: 'Tuvalu'},
  {value: 'UG', label: 'Uganda'},
  {value: 'UA', label: 'Ukraine'},
  {value: 'AE', label: 'United Arab Emirates'},
  {value: 'GB', label: 'United Kingdom'},
  {value: 'US', label: 'United States'},
  {value: 'UY', label: 'Uruguay'},
  {value: 'UZ', label: 'Uzbekistan'},
  {value: 'VU', label: 'Vanuatu'},
  {value: 'VE', label: 'Venezuela, Bolivarian Republic of'},
  {value: 'VN', label: 'Vietnam'},
  {value: 'VI', label: 'Virgin Islands'},
  {value: 'WF', label: 'Wallis and Futuna'},
  {value: 'EH', label: 'Western Sahara'},
  {value: 'YE', label: 'Yemen'},
  {value: 'ZM', label: 'Zambia'},
  {value: 'ZW', label: 'Zimbabwe'},
]

export const _currencies = [
  // {value: '', label: 'Select a currency...'},
  {value: 'EUR', label: 'EUR - Euro'},
  {value: 'USD', label: 'USD - USA dollar'},
  {value: 'GBP', label: 'GBP - British pound'},
  {value: 'AUD', label: 'AUD - Australian dollar'},
  {value: 'JPY', label: 'JPY - Japanese yen'},
  {value: 'SEK', label: 'SEK - Swedish krona'},
  {value: 'CAD', label: 'CAD - Canadian dollar'},
  {value: 'CHF', label: 'CHF - Swiss franc'},
]

export const _work_permits = [
  {value: 'eu', label: 'EU (Europe)'},
  {value: 'us', label: 'US (United States)'},
  {value: 'uk', label: 'UK (United Kingdom)'},
]

export const _work_modalities = [
  {key: 'hybrid', label: 'Hybrid'},
  {key: 'on_site', label: 'On-Site'},
  {key: 'remote', label: 'Remote'},
]
export const _work_employment_contracts = [
  {key: 'permanent', label: 'Permanent / Undefined'},
  {key: 'fixed_term', label: 'Fixed-term'},
  {key: 'freelance', label: 'Freelance'},
]
export const _seniority_positions = [
  {key: 'junior', label: 'Junior'},
  {key: 'senior', label: 'Senior / Expert'},
  {key: 'manager', label: 'Manager / Lead'},
  {key: 'director', label: 'Director++'},
]

export const _company_types = [
  {key: 'client', label: 'Client'},
  {key: 'service_provider', label: 'Service provider / Consulting'},
]

export const _programming_languages = [
  {key: 'cpp', label: 'C++'},
  {key: 'django', label: 'Django'},
  {key: 'go', label: 'Go'},
  {key: 'java', label: 'Java'},
  {key: 'javascript', label: 'JavaScript'},
  {key: 'kotlin', label: 'Kotlin'},
  {key: 'dotnet', label: '.NET'},
  {key: 'dotnetcore', label: '.NET Core'},
  {key: 'nodejs', label: 'Node.js'},
  {key: 'ruby', label: 'Ruby'},
  {key: 'rubyonrails', label: 'Ruby on Rails'},
  {key: 'scala', label: 'Scala'},
  {key: 'spring', label: 'Spring'},
  {key: 'typescript', label: 'TypeScript'},
  {key: 'vuejs', label: 'Vue.js'},
]

export const _technologies = [
  {key: 'iot', label: 'IoT (Internet of Things)'},
  {key: 'blockchain', label: 'Blockchain'},
  {key: 'cybersecurity', label: 'Cybersecurity'},
  {key: 'mlai', label: 'ML/AI (Machine Learning/Artificial Intelligence)'},
  {key: 'rpa', label: 'RPA (Robotic Process Automation)'},
  {key: 'bigdata', label: 'Big Data'},
  {key: 'cryptocurrencies', label: 'Cryptocurrencies'},
  {key: 'cloud', label: 'Cloud'},
  {key: 'edgecomputing', label: 'Edge Computing'},
  {key: 'quantumcomputing', label: 'Quantum Computing'},
  {key: '5g', label: '5G'},
]

export const _positions = [
  {key: 'software_developer', label: 'Software Developer'},
  {key: 'it_architect', label: 'IT Architect'},
  {key: 'network_engineer', label: 'Network Engineer'},
  {key: 'database_administrator', label: 'Database Administrator'},
  {key: 'system_administrator', label: 'System Administrator'},
  {key: 'security_analyst', label: 'Security Analyst'},
  {key: 'data_scientist', label: 'Data Scientist'},
  {key: 'cloud_engineer', label: 'Cloud Engineer'},
]

export const _education_levels = [
  {value: '', label: 'Select an education level...'},
  {value: 'primary_school', label: 'Primary School'},
  {value: 'high_school', label: 'High School'},
  {value: 'bachelor', label: "Bachelor's Degree"},
  {value: 'master', label: "Master's Degree"},
  {value: 'doctorate', label: 'Doctorate'},
]

export const _industries = [
  {key: 'industrial_goods', label: 'Industrial Goods'},
  {key: 'financial_institutions', label: 'Financial Institutions'},
  {key: 'consumer', label: 'Consumer'},
  {key: 'health_care', label: 'Health Care'},
  {key: 'technology_media_telecom', label: 'Technology, Media and Telecom'},
  {key: 'energy', label: 'Energy'},
  {key: 'insurance', label: 'Insurance'},
  {key: 'public_sector', label: 'Public Sector'},
  {key: 'travel_cities_infrastructure', label: 'Travel, Cities and Infrastructure'},
  {key: 'principal_investors_private_equity', label: 'Principal Investors and Private Equity'},
]

export const _cities_spain = [
  {key: 'a_coruna', label: 'A Coruña'},
  {key: 'alava', label: 'Álava'},
  {key: 'albacete', label: 'Albacete'},
  {key: 'alicante', label: 'Alicante'},
  {key: 'almeria', label: 'Almería'},
  {key: 'asturias', label: 'Asturias'},
  {key: 'avila', label: 'Ávila'},
  {key: 'badajoz', label: 'Badajoz'},
  {key: 'barcelona', label: 'Barcelona'},
  {key: 'burgos', label: 'Burgos'},
  {key: 'caceres', label: 'Cáceres'},
  {key: 'cadiz', label: 'Cádiz'},
  {key: 'cantabria', label: 'Cantabria'},
  {key: 'castellon', label: 'Castellón'},
  {key: 'ceuta', label: 'Ceuta'},
  {key: 'ciudad_real', label: 'Ciudad Real'},
  {key: 'cordoba', label: 'Córdoba'},
  {key: 'cuenca', label: 'Cuenca'},
  {key: 'girona', label: 'Girona'},
  {key: 'granada', label: 'Granada'},
  {key: 'guadalajara', label: 'Guadalajara'},
  {key: 'guipuzcoa', label: 'Guipúzcoa'},
  {key: 'huelva', label: 'Huelva'},
  {key: 'huesca', label: 'Huesca'},
  {key: 'islas_baleares', label: 'Islas Baleares'},
  {key: 'jaen', label: 'Jaén'},
  {key: 'la_rioja', label: 'La Rioja'},
  {key: 'las_palmas', label: 'Las Palmas'},
  {key: 'leon', label: 'León'},
  {key: 'lleida', label: 'Lleida'},
  {key: 'lugo', label: 'Lugo'},
  {key: 'madrid', label: 'Madrid'},
  {key: 'malaga', label: 'Málaga'},
  {key: 'melilla', label: 'Melilla'},
  {key: 'murcia', label: 'Murcia'},
  {key: 'navarra', label: 'Navarra'},
  {key: 'ourense', label: 'Ourense'},
  {key: 'palencia', label: 'Palencia'},
  {key: 'pontevedra', label: 'Pontevedra'},
  {key: 'salamanca', label: 'Salamanca'},
  {key: 'santa_cruz_de_tenerife', label: 'Santa Cruz de Tenerife'},
  {key: 'segovia', label: 'Segovia'},
  {key: 'sevilla', label: 'Sevilla'},
  {key: 'soria', label: 'Soria'},
  {key: 'tarragona', label: 'Tarragona'},
  {key: 'teruel', label: 'Teruel'},
  {key: 'toledo', label: 'Toledo'},
  {key: 'valencia', label: 'Valencia'},
  {key: 'valladolid', label: 'Valladolid'},
  {key: 'vizcaya', label: 'Vizcaya'},
  {key: 'zamora', label: 'Zamora'},
  {key: 'zaragoza', label: 'Zaragoza'},
]
